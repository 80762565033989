<template>
    <el-dialog title="新增退款" :visible.sync="dialogVisible" width="720px"  class="class-dialog" :before-close='onCancel' modal-append-to-body>
        <div class="dialog-inside topuprefund">
            <div class="sunbox">
                <div class="sunbox-text"><span class="text-xing">*</span>端口名称</div>
                <div class="sunbox-content">
                    <el-select v-model="portData" placeholder="请选择端口" clearable @change="onportChange" @clear='ongetAdvertiserList' value-key="id">
                        <el-option v-for="(item,key) in portList" :key="key" :label="item.advertiser_full_name" :value="item"></el-option>
                    </el-select>
                    <!-- <el-input v-model="port_id" placeholder="请输入端口ID"></el-input> -->
                </div>
            </div>
            <div class="sunbox">
                <div class="sunbox-text"><span class="text-xing">*</span>群名称</div>
                <div class="sunbox-content">
                    <el-select v-model="CompanyData" filterable placeholder="请选择群名" @change="oncompanyChange" @clear='ongetTopCompanyList' value-key="id" remote clearable reserve-keyword :remote-method="ongetTopCompanyList">
                        <el-option v-for="item in CompanyList" :key="item.value" :label="item.company_name" :value="item"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="sunbox">
                <div class="sunbox-text"><span class="text-xing">*</span>客户名称</div>
                <div class="sunbox-content">
                    <el-input v-model="customer_name" placeholder="请输入客户名称"></el-input>
                </div>
            </div>
            <div class="sunbox">
                <div class="sunbox-text"><span class="text-xing">*</span>账户名称</div>
                <div class="sunbox-content">
                    <el-select v-model="AccountData" filterable placeholder="请选择账户" value-key="id" @change="onagentChange" remote clearable reserve-keyword @focus='onAccountFocus' :remote-method="ongetTopAgentAccountList">
                        <el-option v-for="(item,key) in AccountList" :key="key" :label="item.agent_account_name" :value="item"></el-option>
                    </el-select>
                    <!-- <el-input v-model="agent_account_id" placeholder="请输入账户ID"></el-input> -->
                </div>
            </div>
            <div class="sunbox">
                <div class="sunbox-text"><span class="text-xing">*</span>运营类型</div>
                <div class="sunbox-content">
                    <el-radio-group v-model="is_operating_type" @change="onCollChenge">
                        <el-radio :label="1">自运营</el-radio>
                        <el-radio :label="2">代运营</el-radio>
                        <el-radio :label="3">渠道</el-radio>
                    </el-radio-group>
                    <!-- <el-input v-model="is_operating_type" placeholder="请输入运营类型"></el-input> -->
                </div>
            </div>
            <div class="sunbox">
                <div class="sunbox-text"><span class="text-xing">*</span>账户类型</div>
                <div class="sunbox-content">
                    <el-radio-group v-model="coll_account_type" @change="onCollChenge">
                        <el-radio :label="1">对公</el-radio>
                        <el-radio :label="2">对私</el-radio>
                        <el-radio :label="3">平充</el-radio>
                    </el-radio-group>
                </div>
            </div>
            <div class="sunbox">
                <div class="sunbox-text"><span class="text-xing">*</span><span style="color:#f00">返点（确认客户充值返点）</span></div>
                <div class="sunbox-content">
                    <el-input v-model="port_rebates_value" placeholder="请输入返点（返点值）"></el-input>
                </div>
            </div>
            <div class="sunbox">
                <div class="sunbox-text"><span class="text-xing">*</span>退款广告币</div>
                <div class="sunbox-content">
                    <el-input v-model="refund_ad_money" placeholder="请输入退款广告币" @change="onRmbTuikuan"></el-input>
                </div>
            </div>
            <div class="sunbox">
                <div class="sunbox-text"><span class="text-xing">*</span>应退人民币</div>
                <div class="sunbox-content">
                    <el-input v-model="refund_yuan_money" placeholder="请输入应退人民币"></el-input>
                </div>
            </div>
            <div class="sunbox">
                <div class="sunbox-text"><span class="text-xing">*</span>约定退款时间</div>
                <div class="sunbox-content">
                    <el-date-picker v-model="refund_time" type="datetime"  value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间"></el-date-picker>
                    <!-- <el-input v-model="refund_time" placeholder="请输入退款时间"></el-input> -->
                </div>
            </div>
            <div class="sunbox">
                <div class="sunbox-text"><span class="text-xing">*</span>收款人账户信息（收款人）</div>
                <div class="sunbox-content">
                    <el-input v-model="coll_name" placeholder="请输入收款人账户信息（收款人）"></el-input>
                </div>
            </div>
            <div class="sunbox">
                <div class="sunbox-text"><span class="text-xing">*</span>收款人账户信息（收款账户）</div>
                <div class="sunbox-content">
                    <el-input v-model="coll_account_info" placeholder="请输入收款人账户信息（收款账户）"></el-input>
                </div>
            </div>
            <div class="sunbox">
                <div class="sunbox-text"><span class="text-xing">*</span>开户行</div>
                <div class="sunbox-content">
                    <el-input v-model="where_it_is" placeholder="请输入开户行"></el-input>
                </div>
            </div>
            <!-- <div class="sunbox">
                <div class="sunbox-text"><span class="text-xing">*</span>值班人员</div>
                <div class="sunbox-content">
                    <el-input v-model="class_name" placeholder="请输入值班人员"></el-input>
                </div>
            </div> -->
            <div class="sunbox">
                <div class="sunbox-text"><span class="text-xing">*</span>备注</div>
                <div class="sunbox-content">
                    <el-input v-model="order_note" placeholder="请输入备注"></el-input>
                </div>
            </div>
            <div class="sunbox">
                <div class="sunbox-text"><span class="text-xing">*</span>实际退款时间</div>
                <div class="sunbox-content" style="display:flex;align-items: center;">
                    <el-date-picker v-model="order_actual_ok_time" type="datetime"  value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间" style="width:300px"></el-date-picker>
                    <span v-if="order_actual_ok_time == ''" style="margin-left:10px,width:100px;color:#f00">未退款</span>
                    <!-- <el-input v-model="refund_time" placeholder="请输入退款时间"></el-input> -->
                </div>
            </div>
            <div class="sunbox">
                <div class="sunbox-text"><span class="text-xing">*</span>实际退款金额</div>
                <div class="sunbox-content">
                    <el-input v-model="actual_refund_amount" placeholder="请输入实际退款金额"></el-input>
                </div>
            </div>
            <div class="sunbox">
                <div class="sunbox-text"><span class="text-xing">*</span>财务退款账户</div>
                <div class="sunbox-content">
                    <el-input v-model="financial_refund_account" placeholder="请输入财务退款账户"></el-input>
                </div>
            </div>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button @click="onCancel" class="button-Dialog">取 消</el-button>
            <el-button type="primary" @click="onSubmit" class="button-Dialog">提 交</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            dialogVisible:false,
            order_id:'',//修改的时候传主键id

            port_id:'',//端口id
            customer_name:'',//客户名称
            company_id:'',//群ID
            agent_account_id:'',//账户id
            coll_account_type:'',//账户类型
            is_operating_type:'',//运营类型：1自运营 2代运营 3渠道
            port_rebates_value:'',//返点（返点值）
            refund_ad_money:'',//退款广告币
            refund_yuan_money:'',//应退人民币
            refund_time:'',//退款时间
            coll_name:'',//收款人账户信息（收款人）
            where_it_is:'',//开户行
            coll_account_info:'',//收款人账户信息（收款账户）
            class_name:localStorage.getItem('TOPUP-NAME'),//值班人员
            order_note:'',//备注
            order_actual_ok_time:'',//实际退款时间
            actual_refund_amount:'',//实际退款金额
            financial_refund_account:'',//财务退款账户

            //端口列表
            portList:[],
            portData:{},//端口选择后的数据
            //群列表
            CompanyList:[],
            CompanyData:{},//群选择后的数据
            //账户列表
            AccountList:[],
            AccountData:{},//账户选择后的数据
        };
    },
    mounted() {
    },

    methods: {
        //计算应退人民币
        onRmbTuikuan(){
            if(this.refund_ad_money || this.is_operating_type || this.port_rebates_value || this.coll_account_type){
                if(this.coll_account_type == 3){
                    this.refund_yuan_money = this.refund_ad_money
                }else{
                    if(this.is_operating_type == 2){
                        this.refund_yuan_money = (this.refund_ad_money*this.port_rebates_value).toFixed(2)
                    }else{
                        this.refund_yuan_money = (this.refund_ad_money/this.port_rebates_value).toFixed(2)
                    }
                }
            }
            // console.log(this.refund_yuan_money,this.refund_ad_money,this.port_rebates_value)
        },
        //打开弹窗接口调用
        onAxCall(){
            this.ongetAdvertiserList()
            this.ongetTopCompanyList()
        },
        //打开弹窗
        onDialogVisible(data){
            this.class_name = localStorage.getItem('TOPUP-NAME')
            if(data){
                this.onModifyData(data)
            }else{
                this.onAxCall()
            }
            this.dialogVisible = true
        },
        // 编辑渲染
        onModifyData(data){
            console.log(data)
            this.order_id = data.id,//修改的时候传主键id

            this.customer_name = data.customer_name,//客户名称
            this.coll_account_type = data.coll_account_type*1,//账户类型
            this.is_operating_type = data.is_operating_type*1,//运营类型：1自运营 2代运营 3渠道
            this.port_rebates_value = data.port_rebates_value,//返点（返点值）
            this.refund_ad_money = data.refund_ad_money,//退款广告币
            this.refund_yuan_money = data.refund_yuan_money,//应退人民币
            this.refund_time = data.refund_time,//退款时间
            this.coll_name = data.coll_name,//收款人账户信息（收款人）
            this.where_it_is = data.where_it_is
            this.coll_account_info = data.coll_account_info,//收款人账户信息（收款账户）
            this.class_name = data.class_name,//值班人员
            this.order_note = data.order_note//备注
            this.order_actual_ok_time = data.order_actual_ok_time == '未退款' ? '' : data.order_actual_ok_time//实际退款时间
            this.actual_refund_amount = data.actual_refund_amount,//实际退款金额
            this.financial_refund_account = data.financial_refund_account,//财务退款账户

            //端口
            this.portList[0] = data.advertiser_msg
            this.portData = data.advertiser_msg,//端口选择后的数据
            this.port_id = data.advertiser_msg.id,//端口id


            //群数据
            this.CompanyList[0] = data.company_msg
            this.CompanyData = data.company_msg,//群选择后的数据
            this.company_id = data.company_msg.id,//群ID

            //账户
            this.AccountList[0] = data.agent_account_msg
            this.AccountData = data.agent_account_msg,//账户选择后的数据
            this.agent_account_id = data.agent_account_msg.id//账户id

            console.log(this.port_id,this.portList)



        },
        //端口列表
        ongetAdvertiserList(){
            var param = {
                deleteJiaZai:true
            };
            this.$service.get(this.$api.getAdvertiserList,param, (res)=> {
				if(res.code == '200'){
                    this.portList = res.data.data
				}
			})
        },
        //选择端口后触发动作
        onportChange(item){
            this.port_id = item.id
            this.onCollChenge()
        },
        //群列表
        ongetTopCompanyList(query){
            var param = {
                deleteJiaZai:true
            };
            query ? param.company_name = query : '',
            this.$service.get(this.$api.getTopCompanyList, param, (res) => {
                if (res.code == "200") {
                    this.CompanyList = res.data.data;
                }
            });
        },
        //群选择完后触发
        oncompanyChange(item){
            this.company_id = item.id
            item.corporate_account_msg ? this.customer_name = item.corporate_account_msg.corporate_account_name : ''
            this.onCollChenge()
            this.ongetTopAgentAccountList()
        },
        //账户列表获取焦点时触发
        onAccountFocus(event){
            if(this.company_id == ''){ 
                this.$message({message: '请先选择群',type: 'warning'})
                return  event.target.blur();
            }
        },
        //账户列表
        ongetTopAgentAccountList(query){
            var param = {
                deleteJiaZai:true
            };
            query ? param.agent_account = query : '',
            this.$service.get(this.$api.getTopAgentAccountList, param, (res) => {
                if (res.code == "200") {
                    this.AccountList = res.data.data;
                }
            });
        },
        //选择账户触发动作
        onagentChange(item){
            this.is_operating_type = item.is_operating_type*1//运营类型
            this.agent_account_id = item.id
            this.onCollChenge()
        },
        //账户类型选择后触发动作
        onCollChenge(){
            if(this.coll_account_type == 3){
                this.port_rebates_value = '1.000'
                this.onRmbTuikuan()
                return
            }
            if(this.port_id == '' || this.company_id == '' || this.agent_account_id == '' || this.coll_account_type == ''){
                return
            }else{
                var goo_contrary_to_port_rebates = this.portData.goo_contrary_to_port_rebates; //对私自运营
                var goo_private_port_rebates = this.portData.goo_private_port_rebates; //对私代运营
                var sto_contrary_to_port_rebates = this.portData.sto_contrary_to_port_rebates; //对公自运营
                var sto_private_port_rebates = this.portData.sto_private_port_rebates; //对公代运营、
                var channel_private_port_rebates = this.portData.channel_private_port_rebates; //对公自运营
                var channel_contrary_to_port_rebates = this.portData.channel_contrary_to_port_rebates; //对公代运营、
                var num = {
                    22: goo_private_port_rebates,
                    12: goo_contrary_to_port_rebates,
                    21: sto_private_port_rebates,
                    11: sto_contrary_to_port_rebates,
                    23: channel_private_port_rebates,
                    13: channel_contrary_to_port_rebates,
                };
                this.port_rebates_value = num[( (this.coll_account_type.toString()) + (this.is_operating_type.toString()) ) * 1]
            }
            this.onRmbTuikuan()
        },
        //提交
		onSubmit(){
            if(!this.port_id){ return this.$message({message: '请选择端口',type: 'warning'})}
            if(!this.customer_name){ return this.$message({message: '请选择客户名称',type: 'warning'})}
            if(!this.company_id){ return this.$message({message: '请选择群ID',type: 'warning'})}
            if(!this.agent_account_id){ return this.$message({message: '请选账户id',type: 'warning'})}
            if(!this.coll_account_type){ return this.$message({message: '请选择账户类型',type: 'warning'})}
            if(!this.is_operating_type){ return this.$message({message: '请选择运营类型',type: 'warning'})}
            if(!this.port_rebates_value){ return this.$message({message: '返点值为空',type: 'warning'})}
            if(!this.refund_ad_money){ return this.$message({message: '请填写退款广告币',type: 'warning'})}
            if(!this.refund_yuan_money){ return this.$message({message: '请填写应退人民币',type: 'warning'})}
            if(!this.refund_time){ return this.$message({message: '请填退款时间',type: 'warning'})}
            if(!this.coll_name){ return this.$message({message: '请填写收款人账户信息（收款人）',type: 'warning'})}
            if(!this.where_it_is){ return this.$message({message: '请填写开户行',type: 'warning'})}
            if(!this.coll_account_info){ return this.$message({message: '请填写收款人账户信息（收款账户）',type: 'warning'})}
			var param = {
				port_id : this.port_id,//端口id
                customer_name : this.customer_name,//客户名称
                company_id : this.company_id,//群ID
                agent_account_id : this.agent_account_id,//账户id
                coll_account_type : this.coll_account_type,//账户类型
                is_operating_type : this.is_operating_type,//运营类型：1自运营 2代运营 3渠道
                port_rebates_value : this.port_rebates_value,//返点（返点值）
                refund_ad_money : this.refund_ad_money,//退款广告币
                refund_yuan_money : this.refund_yuan_money,//应退人民币
                refund_time : this.refund_time,//退款时间
                coll_name : this.coll_name,//收款人账户信息（收款人）
                where_it_is:this.where_it_is,//开户行
                coll_account_info : this.coll_account_info,//收款人账户信息（收款账户）
                class_name : this.class_name,//值班人员
                order_note : this.order_note,//备注
                order_actual_ok_time:this.order_actual_ok_time ? this.order_actual_ok_time : '未退款',//实际退款时间
                actual_refund_amount : this.actual_refund_amount,//实际退款金额
                financial_refund_account : this.financial_refund_account,//财务退款账户
			}
            this.order_id ? param.order_id = this.order_id : ''
			console.log(param)
			this.$service.post(this.$api.createTopOrderRefund,param, (res)=> {
				if(res.code == '200'){
					this.$message({message: '提交成功',type: 'success'});
                    this.$parent.ongetTopOrderRefundList()
                    this.onCancel()
				}
			})
		},
		//取消
		onCancel(){
            this.dialogVisible = false
			this.order_id = '',//修改的时候传主键id

            this.port_id = '',//端口id
            this.customer_name = '',//客户名称
            this.company_id = '',//群ID
            this.agent_account_id = '',//账户id
            this.coll_account_type = '',//账户类型
            this.is_operating_type = '',//运营类型：1自运营 2代运营 3渠道
            this.port_rebates_value = '',//返点（返点值）
            this.refund_ad_money = '',//退款广告币
            this.refund_yuan_money = '',//应退人民币
            this.refund_time = '',//退款时间
            this.coll_name = '',//收款人账户信息（收款人）
            this.coll_account_info = '',//收款人账户信息（收款账户）
            this.where_it_is = '',//开户行
            this.class_name = localStorage.getItem('TOPUP-NAME'),//值班人员
            this.order_note = '',//备注
            this.order_actual_ok_time = ''//时间退款时间
            this.actual_refund_amount = '',//实际退款金额
            this.financial_refund_account = '',//财务退款账户

            //端口列表
            this.portList = [],
            this.portData = {},//端口选择后的数据
            //群列表
            this.CompanyList = [],
            this.CompanyData = {},//群选择后的数据
            //账户列表
            this.AccountList = [],
            this.AccountData = {}//账户选择后的数据
		},
    },


    // methods : {
    // 测试用
    // onceshi(){
    // 	this.$service.get(this.$api.getWxpublicArt,'', headers, (res)=> {
    // 		console.log(123)
    // 		if(res.code == '200'){

    // 		}
    // 	})
    // },
    // }
};
</script>

<style lang="scss" scoped>
</style>
<style lang="scss">
.topuprefund{
    &.dialog-inside{
        .sunbox-text{
            width: 200px;
        }
        .sunbox-content,.el-select,.el-date-editor{
            width: 400px;
            .el-input__icon{
                line-height: 30px;
            }
        }
    }
}
</style>