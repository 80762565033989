<template>
    <div class="topuprefund">
        <div class="padd20 borbut20" style="position: relative;">
            <div class="financiallistFrome active">
                <div class="topSearchSou">
                    <div class="searchdan">
                        <div class="title">值班人员：</div>
                        <el-input v-model="class_name" placeholder="请输入内容"></el-input>
                    </div>
                    <div class="searchdan">
                        <div class="title">端口ID：</div>
                        <el-select v-model="port_id" placeholder="请选择端口" clearable>
                            <el-option v-for="(item, key) in portList" :key="key" :label="item.advertiser_full_name"
                                :value="item.id"></el-option>
                        </el-select>
                    </div>
                    <div class="searchdan">
                        <div class="title">群ID：</div>
                        <el-select v-model="company_id" placeholder="请选择群" multiple filterable remote reserve-keyword
                            clearable :remote-method="ongetTopCompanyList">
                            <el-option v-for="(item, key) in CompanyList" :key="key" :label="item.company_name"
                                :value="item.id"></el-option>
                        </el-select>
                    </div>
                    <div class="searchdan">
                        <div class="title">账户名称：</div>
                        <el-select v-model="agent_account_id" placeholder="请选择账户" multiple filterable remote reserve-keyword
                            clearable :remote-method="ongetTopAgentAccountList">
                            <el-option v-for="(item, key) in AccountList" :key="key" :label="item.agent_account_name"
                                :value="item.id"></el-option>
                        </el-select>
                    </div>
                    <div class="searchdan time">
                        <div class="title">实际退款时间：</div>
                        <el-date-picker v-model="pickerTime" type="daterange" range-separator="至" start-placeholder="开始日期"
                            end-placeholder="结束日期" value-format="yyyy-MM-dd"></el-date-picker>
                    </div>

                    <div class="searchdan">
                        <div class="title">客户名称：</div>
                        <el-input v-model="customer_name" placeholder="请输入客户名称"></el-input>
                    </div>
                    <div class="searchdan">
                        <div class="title">运营类型：</div>
                        <el-select v-model="is_operating_type" placeholder="请选择运营类型">
                            <el-option v-for="(item, key) in operTypeList" :key="key" :label="item.lable"
                                :value="item.value"></el-option>
                        </el-select>
                    </div>
                    <div class="searchdan time">
                        <div class="title">约定退款时间：</div>
                        <el-date-picker v-model="order_actual_ok_time" type="daterange" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"></el-date-picker>
                    </div>
                    <div class="searchdan time">
                        <div class="title">提交时间：</div>
                        <el-date-picker v-model="submit_time" type="daterange" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"></el-date-picker>
                    </div>
                </div>
            </div>
            <div class="RightBotton">
                <el-button type="primary" icon="el-icon-search" @click="onSearch()">搜索</el-button>
                <el-button class="Shuarefresh" @click="$router.go(0)" icon='el-icon-refresh-right'
                    style="margin-left:0;">重置数据</el-button>
            </div>
        </div>
        <div class="finanOrderStatus padd20 borbut20 ">
            <div class="finantitle">退款状态：</div>
            <div class="statusList">
                <div class="dan" v-for="(item, key) in OrderStatus" :key="key" :class="status == item.value ? 'active' : ''"
                    @click="onOrderStatus(item)">{{ item.label }}</div>
            </div>
        </div>
        <div class="tableData padd20">
            <div class="duoshao">
                <div class="title">充值退款
                    <el-button type="primary" icon="el-icon-upload" @click="onDialog()">新增退款</el-button>

                </div>
                <div class="jiaoban" style="display:flex; margin-left:30px">
                    <el-input v-model="jiaobaninput" placeholder="请输入交班人员姓名"></el-input>
                    <el-button type="primary" @click="onClassJiaoban()">交班</el-button>
                </div>
                <div class="lvtotal"><a class="download" @click="onExport" target="_blank">导出<i
                            class="iconfont icon-xiazai"></i></a>总条数：{{ lvtotal }} 条</div>
            </div>
            <div class="TopupList">
                <div class="headtop">
                    <!-- <p style="width:105px;">订单编号</p> -->
                    <p>提交时间</p>
                    <p>值班人员</p>
                    <p>群名称</p>
                    <p>运营类型</p>
                    <p>账户名称</p>
                    <p>账户ID</p>
                    <p>端口名称</p>
                    <p>返点</p>
                    <p>退款广告币</p>
                    <p>退款人民币</p>
                    <p>约定退款时间</p>
                    <p>收款人账户信息（收款人）</p>
                    <p>开户行</p>
                    <p>收款人账户信息（收款账户）</p>
                    <p>端口名称（全称）</p>
                    <p>客户名称</p>
                    <p>实际退款时间</p>
                    <p>实际退款金额</p>
                    <p>财务退款账户</p>
                    <p style="width:150px;">操作</p>
                </div>
                <div class="listbody">
                    <div class="dan" v-for="(item, key) in tableData" :key="key">
                        <!-- <div class="cebian"><p style="width:105px;">{{item.order_num}}</p></div> -->
                        <div class="shujubian">
                            <div class="neirong">
                                <p>{{ item.created_at }}</p>
                                <p>{{ item.class_name }}</p>
                                <p>{{ item.company_msg.company_name }}</p>
                                <p>{{ item.is_operating_type == 1 ? '自运营' : item.is_operating_type == 2 ? '代运营' : '渠道' }}
                                </p>
                                <p>{{ item.agent_account_msg.agent_account_name }}</p>
                                <p>{{ item.agent_account_msg.agent_account }}</p>
                                <p>{{ item.advertiser_msg.advertiser_name }}</p>
                                <p>{{ item.port_rebates_value }}</p>
                                <p>{{ item.refund_ad_money }}</p>
                                <p>{{ item.refund_yuan_money }}</p>
                                <p>{{ dateFtt("yyyy-MM-dd", new Date(item.refund_time)) }}</p>
                                <p>{{ item.coll_name }}</p>
                                <p>{{ item.where_it_is }}</p>
                                <p>{{ item.coll_account_info }}</p>
                                <p>{{ item.advertiser_msg.advertiser_full_name }}</p>
                                <p>{{ item.customer_name }}</p>
                                <p><span style="color:#f00;font-weight:400">{{ item.order_actual_ok_time }}</span></p>
                                <p><span style="color:#f00">{{ item.actual_refund_amount }}</span></p>
                                <p><span style="color:#f00">{{ item.financial_refund_account }}</span></p>
                                <p style="width:150px;">
                                    <!-- <el-button type="danger" style="margin-left:0" @click="onDelete(item)" plain>删除</el-button> -->
                                    <el-button type="primary" @click="onDialog(item)">编辑</el-button>
                                </p>
                            </div>
                            <div class="beizhu">
                                备注：<el-input type="textarea" :rows="1" @change="onOrderNote(item)" placeholder="请输入内容"
                                    v-model="item.order_note"></el-input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="InforList">
                <div class="infodan" v-for="(item,key) in tableData" :key="key">
                    <div class="inforTop">
                        <div class="dan">
                            <p>订单编号：{{item.order_num}}</p>
                        </div>
                        <div class="dan">
                            <p>{{item.created_at}}</p>
                            <el-button type="danger" @click="onDelete(item)" plain>删除</el-button>
                            <el-button type="primary" @click="onDialog(item)">编辑</el-button>
                        </div>
                    </div>
                    <div class="infotext">
                        <div class="dan">
                            <p><span>值班人员</span><span style="color:#f00;font-weight:400">{{item.class_name ? item.class_name : '--'}}</span></p>
                            <p><span>客户名称</span>{{item.customer_name ? item.customer_name : '--'}}</p>
                            <p><span>运营类型</span>{{item.is_operating_type == 1 ? '自运营' : item.is_operating_type == 2 ? '代运营' : '渠道'}}</p>
                            <p><span>返点</span>{{item.port_rebates_value ? item.port_rebates_value : '--'}}</p>
                            <p><span>退款广告币</span>{{item.refund_ad_money ? item.refund_ad_money : '--'}}</p>
                            <p><span>退款人民币</span>{{item.refund_yuan_money ? item.refund_yuan_money : '--'}}</p>
                        </div>
                        <div class="dan">
                            
                            <p><span>约定退款时间</span>{{item.refund_time ? item.refund_time : '--'}}</p>
                            <p><span>实际退款时间</span> <span style="color:#f00;font-weight:400" v-if="item.order_actual_ok_time == '未退款'">{{item.order_actual_ok_time}}</span><span v-else>{{item.order_actual_ok_time ? item.order_actual_ok_time : '--'}}</span></p>
                            <p><span>开户行</span>{{item.where_it_is ? item.where_it_is : '--'}}</p>
                            <p><span>收款人账户信息（收款人）</span>{{item.coll_name ? item.coll_name : '--'}}</p>
                            <p><span>收款人账户信息（收款账户）</span>{{item.coll_account_info ? item.coll_account_info : '--'}}</p>
                        </div>
                        <div class="dan">
                            <p><span>群名称</span>{{item.company_msg.company_name ? item.company_msg.company_name : '--'}}</p>
                            <p><span>账户ID</span>{{item.agent_account_msg.agent_account ? item.agent_account_msg.agent_account : '--'}}</p>
                            <p><span>账户名称</span>{{item.agent_account_msg.agent_account_name ? item.agent_account_msg.agent_account_name : '--'}}</p>
                            <p><span>端口名称</span>{{item.advertiser_msg.advertiser_name ? item.advertiser_msg.advertiser_name : '--'}}</p>
                            <p><span>端口名称（全称）</span>{{item.advertiser_msg.advertiser_full_name ? item.advertiser_msg.advertiser_full_name : '--'}}</p>
                        </div>
                    </div>
                    <div class="infobottom">
                        备注：<el-input type="textarea" :rows="2" @change="onOrderNote(item)" placeholder="请输入内容" v-model="item.order_note"></el-input>
                    </div>
                </div>
                
            </div> -->
            <el-pagination class="lv-page" :page-size="per_page" :pager-count="11" @current-change="DialogCurrentChange"
                background layout="prev, pager, next" :total="lvtotal" hide-on-single-page
                :current-page.sync="paramPage"></el-pagination>
        </div>
        <TopuprefundDialog ref="TopuprefundDialog"></TopuprefundDialog>
    </div>
</template>

<script>
import TopuprefundDialog from './topuprefundDialog.vue'
export default {
    data () {
        return {
            paramData: {},//表格参数
            per_page: 10, //一页多少条
            lvtotal: null, //共几条数据
            paramPage: 1,//表格当前第几页
            tableData: [],//跨端口列表

            jiaobaninput: localStorage.getItem('TOPUP-NAME'),//值班人员


            // 搜索参数
            class_name: '',//提交人（商务值班人员）
            pickerTime: [],//订单时间
            port_id: '',//端口id
            customer_name: '',//客户名称
            company_id: '',//群id
            agent_account_id: '',//账户id
            is_operating_type: '',//运营类型id


            //端口
            portList: [],
            //群
            CompanyList: [],
            //账户
            AccountList: [],
            //约定退款时间
            order_actual_ok_time: [],
            //提交时间
            submit_time: [],
            //运营类型
            operTypeList: [
                { value: 1, lable: '自运营' },
                { value: 2, lable: '代运营' },
                { value: 3, lable: '渠道' },
            ],
            status: '',
            OrderStatus: [
                {
                    value: '',
                    label: '全部'
                },
                {
                    value: '未退款',
                    label: '未退款'
                }
            ]
        };
    },
    components: {
        TopuprefundDialog
    },

    mounted () {
        this.ongetTopOrderRefundList()
        this.ongetAdvertiserList()
        this.ongetTopCompanyList()
        this.ongetTopAgentAccountList()
    },

    methods: {
        //订单状态点击
        onOrderStatus (item) {
            this.status = item.value
            this.ongetTopOrderRefundList()
        },
        //导出
        onExport () {
            var UrlParam = '?start_created_at=' + (this.pickerTime.length ? this.pickerTime[0] : "") +  //开始日期
                '&end_created_at=' + (this.pickerTime.length ? this.pickerTime[1] : "") + //结束日期
                '&port_id=' + (this.port_id ? this.port_id : '') +
                '&customer_name=' + (this.customer_name ? this.customer_name : '') +
                '&company_id=' + (this.company_id ? this.company_id : '') +
                '&agent_account_id=' + (this.agent_account_id ? this.agent_account_id : '') +
                '&is_operating_type=' + (this.is_operating_type ? this.is_operating_type : '') +
                '&class_name=' + (this.class_name ? this.class_name : '') +
                '&start_refund_time=' + (this.order_actual_ok_time.length ? this.order_actual_ok_time[0] : "") +  //开始日期
                '&end_refund_time=' + (this.order_actual_ok_time.length ? this.order_actual_ok_time[1] : "") + //结束日期
                '&start_record_created_at=' + (this.submit_time.length ? this.submit_time[0] : "") +  //提交退款开始日期
                '&end_record_created_at=' + (this.submit_time.length ? this.submit_time[1] : "") + //提交退款结束日期
                '&order_actual_ok_time_status=' + (this.status ? this.status : '') + //退款状态
                '&export=1' +
                '&per_page=9999' +
                '&token=' + JSON.parse(localStorage.getItem('TZ-USER')).admin_token
            // console.log(UrlParam,this.order_actual_ok_time,this.pickerTime)
            window.open(this.$api.getTopOrderRefundList + UrlParam)
        },
        //搜索
        onSearch () {
            this.paramData = {
                start_created_at: this.pickerTime ? this.pickerTime[0] : "", //开始日期
                end_created_at: this.pickerTime ? this.pickerTime[1] : "", //结束日期
                port_id: this.port_id,
                customer_name: this.customer_name,
                company_id: this.company_id,
                agent_account_id: this.agent_account_id,
                is_operating_type: this.is_operating_type,
                class_name: this.class_name,
                start_refund_time: this.order_actual_ok_time ? this.order_actual_ok_time[0] : "", //约定退款开始日期
                end_refund_time: this.order_actual_ok_time ? this.order_actual_ok_time[1] : "", //约定退款结束日期
                start_record_created_at: this.submit_time ? this.submit_time[0] : "", //提交退款开始日期
                end_record_created_at: this.submit_time ? this.submit_time[1] : "", //提交退款结束日期
            }
            this.ongetTopOrderRefundList()
        },
        //端口列表
        ongetAdvertiserList () {
            var param = {
                deleteJiaZai: true
            };
            this.$service.get(this.$api.getAdvertiserList, param, (res) => {
                if (res.code == '200') {
                    this.portList = res.data.data
                }
            })
        },
        //群列表
        ongetTopCompanyList (query) {
            console.log(query)
            var param = {
                deleteJiaZai: true
            };
            query ? param.company_name = query : '',
                this.$service.get(this.$api.getTopCompanyList, param, (res) => {
                    if (res.code == "200") {
                        this.CompanyList = res.data.data;
                    }
                });
        },
        //账户列表
        ongetTopAgentAccountList (query) {
            var param = {
                deleteJiaZai: true
            };
            query ? param.agent_account = query : '',
                this.$service.get(this.$api.getTopAgentAccountList, param, (res) => {
                    if (res.code == "200") {
                        this.AccountList = res.data.data;
                    }
                });
        },
        //交班
        onClassJiaoban () {
            if (this.jiaobaninput) {
                localStorage.setItem("TOPUP-NAME", this.jiaobaninput);  //账户名
                this.$message({ message: '交班成功', type: 'success' });
            } else {
                this.$message({ message: '请提交交班人员姓名', type: 'warning' });
            }
        },
        //新增退款
        onDialog (data) {
            if (localStorage.getItem('TOPUP-NAME')) {
                this.$refs.TopuprefundDialog.onDialogVisible(data)
            } else {
                this.$message({ message: '请提交交班人员姓名', type: 'warning' });
            }
        },
        //删除
        onDelete (item) {
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                var param = {
                    order_id: item.id,
                }
                this.$service.post(this.$api.delOrderRefundNote, param, (res) => {
                    if (res.code == '200') {
                        this.$message({ message: '删除成功', type: 'success' });
                        this.ongetTopOrderRefundList()
                    }
                })
            }).catch(() => {
                this.$message({ type: 'info', message: '已取消删除' });
            });

        },
        //编辑备注
        onOrderNote (item) {
            var param = {
                order_id: item.id,
                order_note: item.order_note,
                deleteJiaZai: true
            }
            this.$service.post(this.$api.updateOrderRefundNote, param, (res) => {
                if (res.code == '200') {
                }
            })
        },
        //跨端口列表list
        ongetTopOrderRefundList () {
            this.tableData = [];
            var param = this.paramData
            param.page = this.paramPage
            param.per_page = 20
            param.order_actual_ok_time_status = this.status
            console.log(param)
            this.$service.get(this.$api.getTopOrderRefundList, param, (res) => {
                if (res.code == '200') {
                    this.tableData = res.data.data
                    this.lvtotal = res.data.total
                }
            })
        },
        // 分页
        DialogCurrentChange (val) {
            this.paramPage = val
            this.ongetTopOrderRefundList()
        },

    }
};
</script>

<style lang="scss" scoped></style>
<style lang="scss">
.topuprefund {
    .topSearchSou {
        width: calc(100% - 150px);
        display: flex;
        flex-wrap: wrap;
        justify-content: left;

        .searchdan {
            &.time {}
        }
    }

    .financiallistFrome {
        position: relative;
        display: flex;
        // align-items: center;
        height: 40px;
        transition: .5s;

        &.active {
            transition: .5s;
            height: 120px;
        }

        .RightBotton {
            width: 150px;
        }

        .topSearchSou {
            // overflow: hidden;

            .searchdan {
                margin-bottom: 10px;
            }

            .searchdan:first-child {
                margin-left: 20px;
            }
        }
    }

    .TopupList {
        border-radius: 4px;
        overflow: hidden;

        .headtop {
            display: flex;
            align-items: center;
            background: #eef6fe;
            border-bottom: 1px solid #ededed;

            p {
                word-break: break-all;
                padding: 15px;
                text-align: center;
                color: #333;
                font-weight: 500;
                width: 4.78%;
                flex-shrink: 0;
            }
        }

        .listbody {
            p {
                word-break: break-all;
                padding: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                width: 4.78%;
                flex-shrink: 0;
            }

            .dan {
                width: 100%;
                display: flex;
                align-items: center;
                border-bottom: 1px solid #ededed;

                &:hover {
                    background: #fafafa;
                }

                &:nth-child(2n) {
                    background: #eef6fe;
                }
            }

            .cebian {
                word-break: break-all;
                display: flex;
                align-items: center;
                text-align: center;
            }

            .shujubian {

                // border-left: 1px solid #ededed;
                .neirong {
                    display: flex;
                    border-bottom: 1px solid #ededed;
                }
            }

            .beizhu {
                display: flex;
                padding: 20px;
                align-items: top;

                .el-textarea {
                    width: calc(100% - 50px);
                }
            }
        }
    }

    .InforList {
        p {
            color: #333;
        }

        .inforTop {
            border-bottom: 1px solid #ededed;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 15px 20px;
            background: #eaf7ff;

            .dan {
                display: flex;
                align-items: center;

                p {
                    margin-right: 20px;
                }
            }
        }

        .infotext {
            display: flex;
            flex-wrap: wrap;

            .dan {
                padding: 15px 20px;
                border-left: 1px solid #ededed;
                width: 33%;

                &:first-child {
                    border-left: none;
                }

                p {
                    line-height: 35px;

                    span {
                        display: inline-block;
                        min-width: 130px;
                        font-weight: 500;
                    }
                }
            }
        }

        .infodan {
            margin-bottom: 20px;
            border: 1px solid #ededed;
        }

        .infobottom {
            border-top: 1px solid #ededed;
            padding: 20px;
            display: flex;
            align-items: top;

            .el-textarea {
                width: calc(100% - 50px);
            }
        }
    }
}
</style>